.aba-early-section {
    display: flex;
    justify-content: space-between;

    h2 {
        color: #FFFFFF;
        background-color: #97C230;
        padding: 30px;
        font-size: 38px;
    }

    .abc-objective {
        h3 {
            font-weight: bold;
            font-size: 30px;
        }

        p {
            font-size: 24px;
        }
    }

    img {
        width: -webkit-fill-available;
    }

}

.school-plan-section {
    h2 {
        color: #FFFFFF;
        background-color: #F1852D;
        padding: 30px;
        font-size: 38px;
    }
}

.acc-key-points {
    h3 {
        font-weight: bold;
        font-size: 30px;
    }

    ul li {
        font-size: 24px;
        list-style: none;
        display: flex;
        align-items: center;
    }

    .point-one li::before {
        content: "\2022";
        color: #E51B20;
        font-size: 100px;
        margin-right: 10px;
    }

    .point-two li::before {
        content: "\2022";
        color: #97C230;
        font-size: 100px;
        margin-right: 10px;
    }

    .point-three li::before {
        content: "\2022";
        color: #F1E60D;
        font-size: 100px;
        margin-right: 10px;
    }

    .point-four li::before {
        content: "\2022";
        color: #1F4999;
        font-size: 100px;
        margin-right: 10px;
    }
}

@media screen and (max-width:1200px) {
    .aba-section {
        margin-top: 90px !important;
    }

    .aba-early-section {
        display: grid;

        h2 {
            padding: 20px;
            font-size: 30px !important;
            margin-top: 0;
        }

        .abc-objective {
            h3 {
                font-size: 28px;
            }

            p {
                font-size: 22px;
            }
        }

        .early-plan-image {
            display: flex;
            justify-content: center;

            img {
                width: 700px;
            }
        }

    }

    .acc-key-points {
        h3 {
            font-size: 28px;
        }

        ul li {
            padding: 10px 0;
            font-size: 22px;
        }
    }
}

@media screen and (max-width: 768px) {
    .early-plan-image {
        img {
            width: 450px !important;
        }
    }

    .acc-key-points ul {
        padding-left: 20px;
    }
}

@media screen and (max-width:490px) {
    .aba-early-section {
        display: grid;

        h2 {
            padding: 10px;
            font-size: 28px !important;
        }

        .abc-objective {
            h3 {
                font-size: 24px;
            }

            p {
                font-size: 20px;
            }
        }

        .early-plan-image img {
            width: 280px !important;
        }

    }

    .acc-key-points {
        h3 {
            font-size: 24px;
        }

        ul {
            padding-left: 0;

            li {
                padding: 10px 0;
                font-size: 20px;
            }
        }
    }
}