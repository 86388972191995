.why-us {
  padding: 2rem;  
}

.whyus-header-section {
    text-align: center;
}

.why-us-header{
    padding: 10px;
    color: #000;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%; /* 47.88px */
}

.why-us-subtext{
    margin-bottom: 2rem;
    padding: 10px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

// .whyus-content{
//     padding: 3rem 0rem;
// }

.whyus-card-header {
    display: flex;
    text-align: left;
    place-items: center;
    margin-bottom: 20px;
}

.whyus-items {
    padding: 30px 20px;
}

.w-icon {
    width: 70px;
}

.whyus-title {
    padding-left: 20px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%; /* 30.24px */
}

.whyus-desc{
    text-align: left;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%; /* 25.2px */
}

@media (max-width: 1200px) {
    .w-icon {
        width: 50px !important;
    }
    
    .whyus-title {
        font-size: 20px;
    }
    
    .whyus-desc{
        font-size: 18px;
    }
    .why-us-subtext{
        margin-bottom: 1rem;
    }
}

@media (max-width: 991px) {
    .whyus-items {
        padding: 20px;
    }
}

@media (max-width: 600px) {
    .why-us-header{
        font-size: 30px;
    }
    .why-us-subtext {
        font-size: 20px;
    }
    .whyus-content {
        padding: 2rem 0rem;
    }
    .whyus-desc {
        text-align: center;
    }
    .whyus-card-header {
        flex-direction: column;
        margin-bottom: 10px;
        text-align: center !important;
    }
    .whyus-items {
        padding: 0px;
    }
    .w-icon {
        padding-bottom: 10px;
    }
}


@media (max-width: 490px) {
    .why-us-header{
        font-size: 30px;
    }
    .why-us-subtext {
        font-size: 20px;
    }
    .whyus-content {
        padding: 2rem 0rem;
    }
    .whyus-desc {
        text-align: center;
    }
    .whyus-card-header {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .whyus-items {
        padding: 0px;
    }
    .w-icon {
        padding-bottom: 10px;
    }
}