body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.p-component {
    font-family: 'Poppins', sans-serif !important;
    color: #052941 !important;
}

.p-button {
    color: #ffffff !important;
    padding: 0.6rem 1rem !important;
    font-size: 20px !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 10px !important;
    font-weight: 500 !important;
    border-radius: 4px !important;
    background: #F1852D !important;
    border: none !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.button-style-primary {
    border-radius: 25px !important;
    padding: 0.6rem 1.8rem !important;
    overflow: visible !important;
}

.button-style-white {
    border-radius: 25px !important;
    padding: 0.6rem 1.8rem !important;
    background: #ffffff !important;
    color: #000 !important;
}

.services-buttton {
    background-color: #FFFFFF !important;
    color: #1F4999 !important;
    box-shadow: none !important;
    border: 1px solid #1F4999 !important;
}

.back-button {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;

    a {
        text-decoration: none;
        color: #2C65F9 !important;
        display: flex;
        gap: 10px;
        font-size: 22px;
        margin: 10px 0;
        align-items: center;
    }
}

.get-started-button  {
    background: white !important;
    color: #1F4999  !important;
    border-radius: 25px !important;
    padding: 1rem 2rem !important; 
}