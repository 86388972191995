.insurance {
    padding: 4rem 5rem;
    background: #1F4999;

    .insurance-title {
        text-align: center;
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 2rem;
    }

    .insurance-images {
        width: 200px !important;
    }

    .slick-slider {
        padding: 0px 30px;
    }

    .slick-initialized .slick-slide {
        width: 380px !important;
    }

    .slick-track{
        display: flex;
        place-items: center;
    }

    .slick-prev:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }

    .slick-next:before {
        font-size: 35px;
        opacity: 1;
        line-height: 0;
    }
    .slick-prev, .slick-next{
        display: none !important;
    }
}

@media (max-width: 500px) {
    .insurance-title {
        font-size: 28px !important;
    }
    .insurance {
        padding: 3rem;
    }
}


