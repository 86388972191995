@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');

.our-story-container {
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  
  .our-story-title {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40.32px;
    text-align: center;
    color: #000;
    margin-bottom: 1rem;
  }

  .our-story-text {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: #000;
    margin-bottom: 2rem;

    .additional-text {
      display: block; // Always show on desktop
    }

    .p-button-outlined {
      color: #FFFFFF;
      border-color: #8DAF61;
      background: #8DAF61;
      &:hover {
        background: darken(#8DAF61, 5%);
        border-color: darken(#8DAF61, 5%);
      }
      display: none; // Initially hidden on desktop

      @media (max-width: 768px) {
        display: block; // Show the button on mobile
        font-size: 20px;
        padding: 8px 16px;
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .our-story-container {
    padding: 2rem;
    
    h2 {
      margin: 0;
    }

    .our-story-title {
      font-size: 26px;
      margin-bottom: 0;
    }

    .our-story-text {
      font-size: 20px;
      .additional-text {
        display: none; // Hide by default on mobile

        &.shown {
          display: block; // Show when "View More" is clicked
        }
      }

      margin-bottom: 0;
    }
  }
}
