.banner-section {
  margin-top: 10px;
  .banner-img {
    position: relative;

    img {
      height: 800px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: 100px;
      object-fit: cover;
    }

    .banner-content {
      position: absolute;
      top: 59%;
      left: 26%;
      transform: translate(-50%, -50%);
      padding: 10px;
      width: 45%;

      .content-head {
        color: #fff;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 8px;
      }
      .banner-button {
        padding: 10px 20px 10px 0px;
        Button {
          font-size: 30px !important;
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .banner-section {
    .banner-img {
      img {
        height: 648px;
      }
      .banner-content {
        width: 40% !important;
        padding: 10px 10px;

        .content-head {
          font-size: 55px !important;
          width: max-content;
        }
        .banner-button Button {
          font-size: 30px !important;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .banner-section {
    .banner-img {
      img {
        height: 543px;
        padding-top: 76px !important;
      }
      .banner-content {
        width: 40% !important;
        padding: 10px 10px;

        .content-head {
          font-size: 40px !important;
          width: max-content;
        }
        .banner-button Button {
          font-size: 24px !important;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .banner-section {
    .banner-img {
      img {
        height: 391px;
      }
      .banner-content {
        top: 74%;
        left: 37% !important;
        width: 65% !important;
        .content-head {
          font-size: 30px !important;
        }
        .banner-button {
          Button {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .banner-section {
    .banner-img {
      img {
        height: 400px;
        padding-top: 68px !important;
      }
    }
    .banner-content {
      left: 50% !important;
      padding: 10px !important;
      width: 80% !important;
      top: 77% !important;


      .content-para {
        p {
          font-size: 14px !important;
          display: none;
        }
      }

      .banner-button {
        padding-top: 10px !important;
        Button {
          font-size: 14px !important;
        }
      }
    }
  }
  .banner-section .banner-img .banner-content .content-head {
    font-size: 24px !important;
    padding: 0px !important;
  }
}
