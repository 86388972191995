.acc-footer-section {
  margin: 0px !important;
  padding: 2rem;
  background-color: #1f4999;
  color: white;
  width: 100%;
  justify-content: center;
  img {
    width: 210px !important;
  }
  .p-divider-horizontal {
    width: 71% !important;
  }
}

.quick-links,
.legals,
.social-media {
  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    padding: 8px 0;
  }
  a {
    text-decoration: none;
    color: #ffffff;
  }
}

.logo-desc {
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .acc-footer-section {
    &.grid {
      display: grid !important;
      justify-content: center;
    }
  }
}

@media (max-width: 440px) {
  .acc-footer-section {
    .footer-logo {
      width: 274px !important;
      .p-divider-horizontal {
        width: 100% !important;
      }
    }
  }

  .quick-links h4,
  .legals h4,
  .social-media h4 {
    margin-top: 14px;
  }

  .quick-links,
  .legals,
  .social-media {
    ul li {
      padding: 5px 0;
    }
  }
}
