.contact-us {
    padding-top: 7rem !important;
    overflow-x: hidden;
}

.p-dialog .p-dialog-header {
    background: #052941 !important;
    text-align: center;
    color: #fff !important;
}

.contact-section {
    overflow-y: hidden;
    display: flex;
}

.contact-left-section {
    position: relative;
    padding: 0px !important;


    .contactus-img {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }

    .info {
        position: absolute;
        text-align: center;
        top: 60%;
        left: 48% !important;
        transform: translate(-50%, -50%);
        width: 70%;
        color: #fff;

        .cu-sub-text {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 10px 0px;
        }

        .ceo {
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 10px;
        }

        .contact-info {
            display: flex;
            justify-content: center;

            .phone-text {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-bottom: 15px;
            }

            .ph-no {
                color: #fff;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                /* 30px */
            }

            .or {
                padding: 20px;
                color: #fff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .socials {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .socialicons-list {
                padding: 0px !important;
                text-align: center !important;

                .a {
                    .i {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
}


.left-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-right-section {
        width: 100%;
        padding: 2rem !important;
    }

    .border-left {
        border-left: 10px solid #8daf61;
    }

    .contactus-img {
        width: 100%;
    }

    .client-form {
        padding: 2rem 2rem;
    }



    .text1 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 4rem 2rem;
    }

    .text2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 2rem;
    }

    .contactus-buttons {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 1440px) {
    .contact-section .left-content {
        align-items: baseline;

        .text1 {
            margin: 2rem;
            font-size: 22px;
        }
    }
}

@media (max-width: 1000px) {
    .contact-us {
        padding-top: 6rem !important;
    }

    .contact-section .contact-left-section .info {
        left: 50% !important;
        width: 100%;
        padding: 35px;
    }

    .contact-right-section {
        .border-left {
            .text1 {
                font-size: 22px;
                margin: 2rem 2rem;
            }
        }

        .text2 {
            font-size: 22px;
        }
    }
}

@media (max-width: 850px) {
    .contact-section {
        display: grid !important;

        .col-6 {
            width: 100% !important;
        }
    }
}

@media (max-width: 600px) {
    .contact-left-section {
        .info {
            .cu-sub-text {
                font-size: 20px;
            }

            .ceo {
                font-size: 20px;
            }

            .contact-info {
                .phone-text {
                    font-size: 20px;
                    padding-bottom: 5px;
                }

                .ph-no {
                    font-size: 20px;
                }

                .or {
                    font-size: 20px;
                }

                .socials {
                    font-size: 20px;
                }

                .socialicons-list {
                    .a {
                        .i {
                            font-size: 30px !important;
                        }
                    }
                }
            }
        }
    }

    .contact-right-section {
        .border-left {
            .text1 {
                font-size: 20px;
                margin: 2rem 1rem !important;
            }
        }

        .text2 {
            font-size: 20px;
        }
    }
}

@media (max-width: 490px) {
    .cu-sub-text {
        font-size: 16px !important;
        padding: 10px 0px 10px 0px;
    }

    .ceo {
        display: none;
    }

    .contact-info .phone-text {
        font-size: 16px !important;
        padding: 0px;
    }

    .ph-no {
        margin-bottom: 6px;
        font-size: 18px !important;
    }

    .contact-info .socials {
        font-size: 16px !important;
    }

    .text1 {
        margin: 2rem;
        font-size: 16px !important;
    }

    .text2 {
        font-size: 16px !important;
    }

    .contact-info {
        display: block;
        margin-top: 0px;
    }

    .or {
        display: none;
    }

    .contact-info .socialicons-list {
        margin: 6px;
    }

    .info {
        top: 65% !important;
    }

    .ask-button {
        font-size: 18px !important;
    }

    .contact-us {
        padding-top: 5rem !important;
    }
}

@media (max-width: 350px) {
    .info {
        top: 52% !important;
    }
}