.commitment-section {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  padding-bottom: 0;
  
  .images-and-content {
    display: flex;
    .images-container {
        display: flex;
      .main-image {
        margin-left: -120px;
        padding-top: 40px;
      }
    }

    .text-content {
      padding: 2rem;

      h2 {
        color: #333;
        margin-bottom: 1rem;
        font-size: 32px;
      }

      p {
        line-height: 1.5;
        font-size: 24px;
      }
    }
  }

  .points-container {
    display: flex;
    flex-direction: column;
    margin: 2rem;

    ul li {
      font-size: 24px;
      list-style: none;
      display: flex;
      align-items: center;
    }

    .point-one li::before {
      content: "\2022";
      color: #E51B20;
      font-size: 100px;
      margin-right: 10px;
    }

    .point-two li::before {
      content: "\2022";
      color: #1F4999;
      font-size: 100px;
      margin-right: 10px;
    }

    .point-three li::before {
      content: "\2022";
      color: #F1E60D;
      font-size: 100px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .commitment-section .images-and-content .text-content {
    h2 {
      font-size: 30px !important;
    }

    p {
      font-size: 22px !important;
    }
  }

  .points-container {
    margin: 2rem;

    ul li {
      font-size: 22px !important;
    }
  }
}

@media (max-width: 1100px) {
  .text-content {
    padding-bottom: 0px !important;
    text-align: center;
  }
  .commitment-section .images-and-content {
    display: block;
  }
  .commitment-section .images-and-content .images-container{
    place-content: center;
  }
  .points-container {
    margin: 0px !important;
  }
}


@media (max-width: 992px) {
  .commitment-section {
    .images-and-content {
      .images-container {
        margin-right: 0rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .commitment-section {
    .images-and-content {
      flex-direction: column;
      .text-content {
        // margin-left: 0;
        text-align: center;

        h2 {
          font-size: 28px !important;
          margin-top: 1rem;
        }

        p {
          line-height: 1.5;
          font-size: 20px !important;
        }
      }
    }

    .main-image {
      width: 280px !important;
    }
  }

  .points-container {
    ul {
      padding-left: 0 !important;

      li {
        font-size: 20px !important;
        padding: 10px 0;
      }
    }
  }
}

@media (max-width: 492px) { 
  .commitment-section {
    overflow-x: hidden !important;
  }
  .text-content {
    padding: 0rem !important;
  }
  .commitment-section .images-and-content .images-container .main-image {
    margin-left: -162px !important;
  }
}

@media (max-width: 362px) { 
  .commitment-section {
    padding: 1rem;
  }
  .commitment-section .images-and-content .images-container .main-image {
    margin-left: 0px !important;
    padding: 20px;
  }
  .dots-image {
    display: none !important;
  }
}