.acc-header .logo {
    a {
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
    }
}

.acc-logo {
    width: 75% !important;
}

.menu-toggle {
    cursor: pointer;
    display: none;
}

.menu-toggle.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6px, 6px);
}

.menu-toggle.active .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-6px, -6px);
}

.acc-header {
    padding: 1rem 2rem !important;
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 4px 0px rgba(31, 48, 110, 0.15);
    background: hsla(0, 0%, 100%, .7803921568627451);
    position: fixed;
    top: 0;
    z-index: 999;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    min-height: 75px;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    ul {
        list-style: none;
        display: grid;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
        grid-template-columns: repeat(6, auto);

        li {
            margin: 0 20px;
            text-decoration: none;
            cursor: pointer;
            font-size: 20px;
            transition: 0.5s;

            a {
                text-decoration: none;
                color: #000000;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .menu-toggle {
        cursor: pointer;
    }
}


@media (max-width: 900px) {
    .acc-header {
        ul {
            li {
                margin: 0 15px;
            }
        }
    }
}


@media (max-width: 894px) {

    .acc-header {
        padding: 1rem 2rem 0px !important;
    }

    .menu-toggle {
        display: block;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 1000;
    }

    .bar {
        display: block;
        width: 100%;
        height: 3px;
        background-color: black;
        margin-bottom: 6px;
        transition: transform 0.3s ease;
    }

    .cross .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-6px, 6px);
    }

    .cross .bar:nth-child(2) {
        opacity: 0;
    }

    .cross .bar:nth-child(3) {
        transform: rotate(45deg) translate(-6px, -6px);
    }

    .menu {
        display: none;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 96px;
        background: #052941;
        left: 0;
        text-align: center;
        overflow-y: auto;
    }

    .menu.show-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 2px solid white;
    }

    .menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .menu li {
        margin-bottom: 0.5rem;
    }

    .menu a {
        color: #fff !important;
        text-decoration: none;
    }

    .acc-header {
        nav ul {
            display: block;
            padding: 10rem 20px;

            li {
                padding: 10px;
            }
        }
    }
}

.active-menu-item {
    font-weight: bold;
}

@media (max-width: 1245px) {
    .acc-header {
        ul li {
            margin: 0 10px;
            font-size: 18px;
        }

        .p-button {
            font-size: 18px !important;
        }
    }
}

@media screen and (max-width: 490px) {

    .menu {
        top: 75px;
    }

    .acc-header {
        padding: 1rem 1rem 0px !important;
    }

    .acc-header .acc-logo {
        width: 55% !important;
    }
}

.service {
    border-bottom: 5px solid #E51B20;
}

.parent {
    border-bottom: 5px solid #97C230;
}

.about {
    border-bottom: 5px solid #1F4999;
}