.join-community-container {
    background-image: url('../../../Assets/Parentspage/Get_in_touch.png'); // Replace with the actual desktop background image path
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 68px;
    padding: 2rem;
    text-align: center;
    color: #ffffff;
    margin: 2rem;
    .join-community-content {
      margin: auto;
      max-width: 1200px; 
  
      h2 {
        font-size: 32px;
        margin-bottom: 1rem;
      }
  
      p {
        line-height: 1.5;
        font-size: 24px;
      }
  
      .join-now-button {
        background-color: #FFFFFF;
        color: #000000;
        font-weight: bold;
        border: none;
  
        &:hover {
          background-color: #dddddd;
        }
      }
    }
  }
  
  // Mobile styles
  @media (max-width: 767px) {
    .join-community-container {
      background-image: url('../../../Assets/Parentspage/Get_in_touch.png'); // Using the same image for simplicity
    //   border-radius: 20px;
      padding: 1rem;
      margin: 1rem; 
  
      .join-community-content {
        h2 {
          font-size: 24px; 
          margin-bottom: 0.5rem;
        }
  
        p {
          font-size: 20px; 
          margin-bottom: 1rem;
          padding : 1rem;
        }
  
        .join-now-button {
          width: 210px; 
          height: 58px; 
          line-height: 58px; 
          font-size: 18px; 
          margin: 20px auto; 
          font-weight: bold;
        }
      }
    }
  }
  