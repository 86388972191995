.community-section {
  background: url('../../../Assets/Parentspage/Join_our_community.png') no-repeat center center; // Replace with your actual image path
  background-size: cover;
  color: white;
  text-align: center;
  padding: 50px; 
  overflow: hidden;

  h1 {
    font-size: 38px; 
    margin-bottom: 20px; 
  }

  p {
    margin-bottom: 30px; 
    font-size: 24px; 
  }

  .join-now-button {
    background-color: orange; 
    border: none;
    font-weight: bold;
    &:hover {
      background-color: darken(orange, 10%); 
    }
  }

}
@media screen and (max-width: 768px) {
  .community-section h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .community-section p {
    font-size: 20px;
    line-height: 22px;
  }
}

@media screen and (max-width: 576px) {

  .community-section {
    padding: 20px;
  }
  
  .community-section h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .community-section p {
    font-size: 18px;
    line-height: 24px;
  }
}