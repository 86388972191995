.mainHeader {
  width: 100%;
  text-align: center;
  padding: 4rem 2rem 0rem 2rem;

  h1 {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 0;

  }

  h2 {
    font-size: 40px;
    font-weight: 700;
    margin-top: 0;
  }
}

.autismCareContainer {
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff;
  margin: 2rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .philosophySection {
      .philosophyBox {
        background-color: #F1852D;
        color: white;
        padding: 20px;
        width: auto;
      }

      .philosophyHeader {
        font-size: 36px;
        font-weight: bold;
      }

      .philosophySubText {
        font-size: 24px;
        font-weight: normal;
      }

      .philosophyContent {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        padding-top: 1rem;
      }
    }

    .approachSection {
      padding-top: 1rem;

      .approachHeader {
        font-size: 32px;
        font-weight: bold;
        color: #E51B20;
      }

      .approachContent {
        font-size: 24px;
        font-family: Poppins;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        padding-top: 1rem;
      }
    }
  }

  .imageSection img {
    width: 677px;
  }

  .mobileImage {
    display: none;
  }
}



@media screen and (max-width: 1440px) {
  .mainHeader {

    h1 {
      font-size: 40px !important;
      font-weight: 700;
      letter-spacing: 0;
      text-align: center;
    }

    h2 {
      font-size: 34px;
      font-weight: 700;
    }
  }

  .autismCareContainer {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 20px;

    .content {
      display: flex;
      flex-direction: column;

      .philosophySection {

        .philosophyHeader {
          font-size: 30px;
          font-weight: bold;
        }

        .philosophySubText {
          font-size: 22px;
          font-weight: normal;
          margin-top: 5px;
        }

        .philosophyContent {
          font-size: 22px;
          text-align: left;
        }
      }

      .approachSection {
        .approachHeader {
          font-size: 30px !important;
        }

        .approachContent {
          font-size: 22px;
        }
      }
    }

    .mobileImage {
      display: none;
    }
  }
}

@media screen and (max-width: 1350px) {
  .autismCareContainer {
    flex-direction: column;
    align-items: center;

    .philosophyBox {
      text-align: center;
    }

    .imageSection {
      order: -1;
      margin-bottom: -8px;
    }

    .approachHeader {
      font-size: 24px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .mainHeader {
    h1 {
      font-size: 30px !important;
    }

    h2 {
      font-size: 26px;
    }
  }

  .autismCareContainer {
    padding: 0 !important;

    .philosophyHeader {
      font-size: 28px !important;
    }

    .philosophySubText {
      font-size: 20px !important;
      text-align: center;
    }

    .philosophyContent {
      font-size: 20px !important;
    }

    // .content,
    .imageSection img{
      width: 400px;
    }

    .approachHeader {
      font-size: 28px !important;
    }

    .approachContent {

      font-size: 20px !important;
      text-align: left;
    }
  }

}

@media screen and (max-width: 490px) {
  .mainHeader {
      padding: 3rem 1rem 0;

    h1 {
      font-size: 26px !important;
    }

    h2 {
      font-size: 22px;
    }
  }

  .autismCareContainer {
    margin: 2rem;

    .philosophyBox {
      padding: 10px !important;
    }

    .philosophyHeader {
      font-size: 26px !important;
    }

    .philosophySubText {
      font-size: 18px !important;
      text-align: center;
    }

    .imageSection img{
      width: 280px;
    }

    .approachHeader {
      font-size: 26px !important;
    }
  }

}