.header-container {
  width: auto;
  height: auto;
  overflow: hidden;

  h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.system-banner-section {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  element.style {
    background-color: none;
    overflow: hidden;
  }
  .item {
    flex-grow: 1;
    height: 500px;
    position: relative;
    transition: flex 0.5s;

    .content {
      bottom: 0 !important;
      position: absolute;
      width: 100%;
      text-align: center;
      p {
        padding: 0px 30px 10px 30px;
      }
    }

    .default {
      h2 {
        bottom: 200px !important;
        position: absolute;
        width: max-content;
        text-align: center;
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }

  .item.hovered {
    flex-grow: 2;
  }

  .item[data-order="1"] {
    background-image: url("../../../Assets/Parentspage/On-Site_Psychiatric_Expertise.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="2"] {
    background-image: url("../../../Assets/Parentspage/Personalized_ABA_Therapy.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="3"] {
    background-image: url("../../../Assets/Parentspage/Family_Support_And_Involvement.png");
    background-position: center;
    background-size: cover;
  }
  .item[data-order="4"] {
    background-image: url("../../../Assets/Parentspage/A_Supportive_Enviroment.png");
    background-position: center;
    background-size: cover;
  }

  .item[data-order="1"] .content {
    background-color: #5e8600cc;
    color: white;
  }

  .item[data-order="2"] .content {
    background: #1f4999cc;
    color: white;
  }

  .item[data-order="3"] .content {
    background: #f1852dcc;
    color: white;
  }
  .item[data-order="4"] .content {
    background: #b84593cc;
    color: white;
  }

  .item[data-order="1"] .default {
    background-color: #5e8600cc;
    color: white;
    display: flex;
    justify-content: center;
    .default-label {
      transform: rotate(-90deg);
    }
  }

  .item[data-order="2"] .default {
    background: #1f4999cc;
    color: white;
    display: flex;
    justify-content: center;
    .default-label {
      transform: rotate(-90deg);
    }
  }

  .item[data-order="3"] .default {
    background: #f1852dcc;
    color: white;
    display: flex;
    justify-content: center;
    .default-label {
      transform: rotate(-90deg);
    }
  }
  .item[data-order="4"] .default {
    background: #b84593cc;
    color: white;
    display: flex;
    justify-content: center;
    .default-label {
      transform: rotate(-90deg);
    }
  }
}

@media screen and (max-width: 1440px) {
  .system-banner-section .item.hovered {
    flex-grow: 4 !important;
  }
}

@media screen and (max-width: 767px) {
  .header-container {
    width: auto;
    height: auto;

    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .system-banner-section {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block !important;
    align-items: center;
    element.style {
      background-color: none;
    }
    .item {
      flex-grow: 1;
      height: 150px;
      position: relative;
      transition: flex 0.5s;

      .content {
        bottom: 0 !important;
        position: absolute;
        width: 100%;
        text-align: center;
        p {
          padding-bottom: 40px;
        }
      }

      .default {
        h2 {
          bottom: 42px !important;
          position: absolute;
          width: 100%;
          text-align: center;
          margin: auto auto;
        }
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
      }
    }

    .item.hovered {
      flex-grow: 6;
      height: 450px;
    }

    .item[data-order="1"] {
      background-image: url("../../../Assets/Parentspage/On-Site_Psychiatric_Expertise.png");
      background-position: center;
      background-size: cover;
    }

    .item[data-order="2"] {
      background-image: url("../../../Assets/Parentspage/Personalized_ABA_Therapy.png");
      background-position: center;
      background-size: cover;
    }

    .item[data-order="3"] {
      background-image: url("../../../Assets/Parentspage/Family_Support_And_Involvement.png");
      background-position: center;
      background-size: cover;
    }
    .item[data-order="4"] {
      background-image: url("../../../Assets/Parentspage/A_Supportive_Enviroment.png");
      background-position: center;
      background-size: cover;
    }

    .item[data-order="1"] .content {
      background-color: #5e8600cc;
      color: #ffffff;
    }

    .item[data-order="2"] .content {
      background: #1f4999cc;
      color: #ffffff;
    }

    .item[data-order="3"] .content {
      background: #f1852dcc;
      color: #ffffff;
    }
    .item[data-order="4"] .content {
      background: #b84593cc;
      color: #ffffff;
    }

    .item[data-order="1"] .default {
      background-color: #5e8600cc;
      color: white;
      .default-label {
        transform: none !important;
      }
    }

    .item[data-order="2"] .default {
      background: #1f4999cc;
      color: white;
      .default-label {
        transform: none !important;
      }
    }

    .item[data-order="3"] .default {
      background: #f1852dcc;
      color: white;
      .default-label {
        transform: none !important;
      }
    }
    .item[data-order="4"] .default {
      background: #b84593cc;
      color: white;
      .default-label {
        transform: none !important;
      }
    }
  }
}



.mob-system-banner-section {
  position: relative;
  display: none;

  .content {
    position: relative;
    display: inline-block;
    margin-top: -7px;

    .overlay {
      display: grid;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      padding: 20px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      opacity: 10;
      transition: opacity 0.3s ease;

      h2 {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-top: -6px;
      }

    }

    .image {
      width: 100%;
      height: auto;
    }
  }

}

@media screen and (max-width: 500px) {
  .system-banner-section {
    display: none !important;
  }

  .mob-system-banner-section {
    display: block !important;
  }
}