.our-section {
    .heading {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 5rem;
    }

    .plan-sec {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 50px;
        margin-bottom: 3rem;

        .plan-section {
            width: 450px;
            height: 650px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

            .img-sec {
                width: 100%;
                height: auto;

                img {
                    width: -webkit-fill-available;
                    height: auto;
                    background-size: cover;
                }

            }

            .name {
                font-family: Poppins;
                font-size: 30px;
                font-weight: 700;
                padding: 1rem;
            }

            .content {
                font-family: Poppins;
                font-size: 20px;
                font-weight: 400;
                padding: 1rem;
                height: 190px !important;
            }

            .plan-button {
                padding: 1rem;
            }

            a {
                text-decoration: none;
                color: #1F4999 !important;
            }
        }
    }

    .paragraph {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
    }
}

@media screen and (max-width: 1000px) {
    .our-section {
        .paragraph {
            font-size: 18px !important;
            margin-bottom: 10px !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .acc-section .paragraph {
        font-size: 18px !important;
    }
    .our-section {
        .m-5 {
            margin: 1rem !important;
        }

        .heading {
            font-size: 30px !important;
        }

        .plan-sec {
            .plan-section {
                width: 300px;

                .name {
                    font-size: 22px;
                }

                .content {
                    font-size: 18px;
                    margin-bottom: 50px;
                }

                .plan-button {
                    .services-buttton {
                        font-size: 16px !important;

                    }
                }
            }
        }
    }
}