.calendly-badge-widget .calendly-badge-content {
    background: #EA8254 !important;
    color: white !important;
}

.calendly-button {
    font-size: 25px !important;
    color: white;
    background-color: #052941;
    padding: 10px;
    cursor: pointer;
  }

