.team-section {
  text-align: center;
  padding: 2rem;

  .team-header {
    font-size: 32px;
    margin-bottom: 0.5rem;
  }

  .team-description {
    margin-bottom: 2rem;
    font-size: 24px;
  }

  .team-content {
    text-align: left;

    .team-image {
      max-width: 50%;
      height: auto;
      border-radius: 10px;
    }

    .team-points {

      .point-title {
        font-size: 26px;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;

        &.psychiatrists {
          border-bottom: 5px solid #0000FF;
        }

        &.bcbas {
          border-bottom: 5px solid #00FF00;
        }

        &.rbts {
          border-bottom: 5px solid #FFA500;
        }

        &.support-staff {
          border-bottom: 5px solid #FF0000;
        }
      }

      .point-description {
        font-size: 24px;
        margin-bottom: 2rem;
      }
    }

    .team-grp-img {
      display: flex;
      align-items: center;
    }
  }

}

.team-section-footer .team-footer {
  background-color: #97C230;
  margin-top: 0;
  font-size: 24px;
  color: #FFF;
  padding: 2rem;
  text-align: center;
}

// Mobile-specific styles
@media (max-width: 1220px) {
  .team-section {
    padding: 1rem;

    .team-header {
      font-size: 28px;
    }
    .team-description {
      font-size: 20px !important;
    }

    .team-content {
      flex-direction: column;
      align-items: left;
      text-align: left;

      .team-points {
        max-width: 100%;
        margin: 0 2rem;

        .point-title {
          font-size: 24px !important;
        }

        .point-description {
          font-size: 20px;
        }
      }

      .team-grp-img {
        justify-content: center;
      }
    }
  }

  .team-section-footer .team-footer {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .team-section {
    margin-top: 85px !important;
  }

  .team-section-footer .team-footer {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {

  .team-section .team-content .team-points {
    margin: 1rem;

    .point-title {
      font-size: 20px !important;
    }

    .point-description {
      font-size: 18px;
    }
  }

  .team-section-footer .team-footer {
    font-size: 18px;
  }
}