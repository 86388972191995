.speaker-details-container {
    padding: 8.5rem 4rem 2rem;
}

.speaker-details-container .back-link a {
    text-decoration: none !important;
    display: flex;
    place-items: center;
    color: #3183FF;
    font-size: 20px;
}

.doctor-details-section {
    display: flex;
    margin: auto;
    background: #f9f9f9;
    box-shadow: 0px 6px 8px #00000029;
    padding: 80px;

    .doctor-details-left {
        width: 40%;
        margin: 30px;
        color: #162c53;

        .doctor-name {
            width: fit-content;
            font: bold 30px "Poppins";
            color: #ffffff;
            background-color: #2a2850;
            padding: 10px;
        }

        .doctor-image img {
            margin-top: 20px;
            width: 330px;
        }

        .doctor-description {
            padding: 10px 0;

            h5 {
                font: bold 16px "Poppins";
                color: #3967ac;
                display: flex;
                margin: 0;

                .d-line {
                    height: 20px;
                    width: 3px;
                    display: inline-block;
                    background-color: #3967ac;
                    margin: 0 10px;
                }
            }

            p {
                font: bold 16px "Poppins";
                color: #162c53;
                margin: 0;
            }
        }

        .ratings {
            font: 16px "Poppins";

            svg,
            i {
                color: #5590ff;
            }
        }

        .reviews {
            margin: 15px 0;

            h2 {
                font: bold 20px "Poppins";
            }

            a {
                font: normal 14px "Poppins";
                color: #5590ff;
                display: contents;
            }

            ul {
                list-style-type: none;
                border-left: 3px solid #5590ff;
                margin: 10px;
                padding: 0;

                li {
                    margin-left: 15px;
                    font: normal 18px "Poppins";
                    padding: 8px;
                }
            }
        }

        .speciality {
            h2 {
                font: bold 20px "Poppins";
            }

            ul {
                list-style-type: disc;
                left: -8px;
                position: relative;
                margin: 10px 0;

                li::marker {
                    color: #5590FF;
                }

                li {
                    font: normal normal normal 18px "Poppins";
                    color: #2A2850;
                    padding: 8px;
                }
            }
        }
    }

    .doctor-details-right {
        width: 60%;
        margin: 30px;
        color: #162c53;

        .about-content,
        .experience,
        .memberships,
        .licence {
            margin-top: 20px;

            h2 {
                font: bold 30px "Poppins";
            }

            p {
                padding: 10px 0;
                font: normal 18px "Poppins";
                line-height: 1.5;
                text-align: justify;
            }

            .limitTextHeight {
                height: 70px;
                overflow: hidden;
            }

            .seeMore-btn {
                color: #5590ff;
                font-size: 16px;
                cursor: pointer;
                text-decoration: underline;
            }

            ul {
                list-style-type: disc;
                margin: 10px 0;
                padding-left: 25px;
                position: relative;

                li::marker {
                    color: #5590FF;
                }

                li {
                    font: normal 18px "Poppins";
                    color: #2a2850;
                    padding: 8px;
                    padding-left: 5px;
                }
            }
        }
    }
}

@media screen and (max-width:1400px) {
    .doctor-details-section {
        padding: 15px;
    }
}

@media screen and (max-width:1100px) {

    .speaker-details-container {
        padding: 8rem 2rem 2rem;
    }

    .doctor-details-left {
        .doctor-image {

            img {
                width: 249px;
            }
        }

        .reviews,
        .speciality {
            ul li {
                padding: 5px !important;
            }
        }
    }

    .doctor-details-right {
        .about,
        .experience,
        .memberships,
        .licence {
            margin-top: 15px !important;

            h2 {
                font: normal normal bold 24px "Poppins" !important;
            }
        }

        ul {
            margin: 5px 0 !important;

            li {
                font: normal normal normal 18px "Poppins" !important;
                padding: 5px !important;
            }
        }
    }
}

@media screen and (max-width: 924px) {
    .doctor-details-section {
        flex-wrap: wrap;
        justify-content: center;
    }

    .doctor-details-left {
        margin-bottom: 0 !important;
        width: 100% !important;
    }

    .doctor-details-right,
    .about-content {
        width: 100% !important;
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 490px) {
    .speaker-details-container {
        padding: 6rem 1rem 2rem;
    }

    .doctor-details-section {
        width: 100%;
    }

    .doctor-details-left .doctor-image img {
        width: -webkit-fill-available !important;
    }

    .doctor-details-right {
        margin: 10px !important;

        .about-content h2 {
            margin: 0 !important;
        }
    }
}