.about-us-family-section {
  margin: 5rem 2rem;
  margin-top: 0;

  .mble-img {
    display: none;
  }

  .desktop-img {
    display: block;
  }
}

.about-us-commitment-section {

  display: flex;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  background: #FFF;
  border: 2px solid #F1852D;
  border-radius: 20px;


  .commitment-content {

    h2 {
      color: #000;
      font-size: 32px;
    }

    p {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .about-us-family-section {
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 1220px) {
  .desktop-img {
    display: none !important;
  }

  .mble-img {
    display: flex !important;
    justify-content: center !important;
    margin: 3rem 0;
    position: relative;
    margin-bottom: -55px !important;
  }

  .about-us-commitment-section {
    padding: 1rem;
    padding-top: 3rem;

    .commitment-content {
      text-align: center;

      h2 {
        font-size: 28px !important;
      }

      p {
        font-size: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mble-img img {
    width: 70%;
  }

  .about-us-commitment-section {
    padding: 1rem;
    padding-top: 2rem !important;
    margin-bottom: -85px !important;

    .commitment-content {
      text-align: center;

      h2 {
        font-size: 24px !important;
      }

      p {
        font-size: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .about-us-family-section {
    margin: 150px 0.5rem;
    margin-bottom: 50px;

    .mble-img img {
      width: 90%;
    }
  }

  .about-us-commitment-section {
    margin: 25px 1rem;

    .commitment-content {
      text-align: center;

      p {
        font-size: 18px !important;
      }
    }
  }
}