.banner-container {
  width: 100%;
  padding-top: 110px;
  object-fit: contain;

  img {
    width: 100%;
  }
}

@media screen and (max-width: 1046px) {
  .banner-container {
    padding-top: 80px;
  }
}