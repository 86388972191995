.testmonials {
    width: 100%;
    overflow-x: hidden;
    padding: 2rem 4rem !important;
    .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
        background-color: #1F4999;
        color: white;
    }
    .p-carousel .p-carousel-indicators {
        display: none;
    }
}

.testmonials-heading {
    color: #000;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%; /* 47.88px */
}
.tleft-div{
    padding: 1rem !important;
}

.percentage {
    display: flex;
    padding-top: 30px;
}

.per-num {
    color: #97C230;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%; /* 100.8px */
}

.per-desc {
    display: flex;
    place-items: center;
    padding-left: 10px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%; /* 30.24px */
}

.hear {
    padding-top: 20px;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%; /* 37.8px */
}

.testimonial-card {
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 280px;
}

.testimonial {
   display: flex;
   justify-content: space-between;
   place-items: center;
}

.tuser-name {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 126%; /* 25.2px */
}

.tuser-desc {
    padding-top: 8px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%; /* 17.64px */
}

.rating {
    padding-top: 15px;
    display: flex;
}

@media (max-width: 767px) {
    .tright-div {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .testmonials {
        padding: 2rem !important;
    }
    .per-num {
        font-size: 60px;
    }
    .per-desc {
        font-size: 20px;
    }
    .hear {
        font-size: 28px;
    }
    .testimonial-card {
        margin: 10px !important;
        padding: 12px;
    }
}