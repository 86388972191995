@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

.get-in-touch-container {
    margin-top: 1rem;
    background-size: cover;
    border-radius: 50px; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;

  .get-in-touch-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .text-section {
      flex: 1;

      h2 {
        font-family: 'Poppins', sans-serif;
        font-size: 32px; 
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-size: 24px; 
        font-weight: 400;
        line-height: 30px;
      }
    }

    .action-section {
      display: flex;
      align-items: center;
      gap: 1rem;

      .get-started-button {
        background: white;
        color: #007ad9;
        border-radius: 25px;
        font-weight: bold;
        padding: 1rem 2rem; 

        &:hover {
          background-color: #005ea7; 
          color: white;
        }
      }

      .phone-number {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: white; 
        border-radius: 25px;
        font-weight: bold;
        padding: 1rem 2rem; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        border: 2px solid #FFF;
      }
    }
  }
}

@media (max-width: 768px) {
    .get-in-touch-container {
      flex-direction: column;
      text-align: center;
  
      .get-in-touch-content {
        flex-direction: column;
  
        .action-section {
          flex-direction: column;
          align-items: center;
  
          .get-started-button, .phone-number {
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
    }
  }
  
  
  @media (max-width: 576px) {
    .get-in-touch-container {
      border-radius: 80px; 
      padding: 1.5rem; 
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    .get-in-touch-content {
      padding: 1rem; 
  
      .text-section {
        h2 {
          font-size: 28px !important; 
          line-height: 40px; 
        }
  
        p {
          font-size: 20px !important; 
          line-height: 28px; 
        }
      }
  
      .action-section {
        .get-started-button, .phone-number {
          padding: 0.8rem 1.5rem; 
        }
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .get-in-touch-container {
      padding: 2rem; 
    }
  
    .get-in-touch-content {
      .text-section {
        h2 {
          font-size: 30px !important; 
          line-height: 45px; 
          text-align: center;
        }
  
        p {
          font-size: 20px !important; 
          line-height: 32px; 
        }
      }
  
      .action-section {
        .get-started-button, .phone-number {
          padding: 1rem 2rem; 
        }
      }
    }
  }
  
 
  @media (min-width: 992px) {
    .get-in-touch-container {
      // padding: 3rem;
      margin-left: 3rem;
      margin-right: 3rem; 
    }
  
    .get-in-touch-content {
      .text-section {
        h2 {
          font-size: 34px; 
          line-height: 50px; 
        }
  
        p {
          font-size: 26px; 
          line-height: 36px; 
        }
      }
  
      .action-section {
        .get-started-button, .phone-number {
          padding: 1.2rem 2.5rem; 
        }
      }
    }
  }
