.acc-section {
    margin-top: 10rem !important;

    .heading {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
    }

    .paragraph {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 4rem !important;
    }
}

@media screen and (max-width: 1400px) {
    .acc-section {
        .heading {
            font-size: 36px !important;
        }

        .paragraph {
            font-size: 20px !important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .acc-section {
        .m-5 {
            margin: 1rem !important;
        }
    }

    .paragraph {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 500px) {
    .acc-section {
        margin-top: 7rem !important;

        .heading {
            font-size: 30px !important;
        }

        .acc-image {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}