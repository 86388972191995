.mission-vision-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
    gap: 2rem;
    
    .mission-section, .vision-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
    .vision-section {
      flex-direction: row-reverse;
    }
    .title {
      padding: 1rem;
      font-size: 55px;
      color: #1F499980;
      
      flex-basis: 20%;
      border-radius: 10px;
      font-weight: 800;
    }
    .title1 {
        padding: 1rem;
        font-size: 55px;
        color:#E51B2080;
    
        
        flex-basis: 20%;
        border-radius: 10px;
        font-weight: 800;
      }
    .content {
      padding: 3rem;
      flex-grow: 1;
      margin: 0 1rem;
      border-radius: 30px;
      font-size: 24px;
      
      &.blue-border-box {
        border: 2px solid #1F4999;
      }
      
      &.red-outline-box {
        border: 2px solid #E51B20;
      }
    }
  
    // Responsive adjustments
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0 !important;
  
      .mission-section, .vision-section {
        flex-direction: column;
        align-items: stretch;
      }
  
      .title, .content {
        flex-basis: auto;
        width: 100%;
        margin: 0;
      }
    }

    @media (max-width: 768px) {
      .content {
        // font-size: 24px;
        padding: 1.5rem;
        border-radius: 20px;
      }

      .title, .title1 {
        // Reduce the font size more if needed for mobile
        font-size: 26px;
        margin-bottom: 0;
        padding: 1rem 0;
      }

      .content {
        // Adjust padding and font size for mobile
        padding: 1rem;
        font-size: 18px;
      }
    }

    .mission-section,
    .vision-section {
      margin-bottom: 0 !important;
    }
  }
  