.three-easy-steps {
  width: 100%;
}

.steps-banner {
  background: #678f06;
  padding: 4rem;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
}

.steps {
  padding: 1rem 4rem;
  display: flex;
}

.second-steps {
  padding: 1rem 4rem;
  padding: 1rem 4rem;
  display: flex;
}

.easy {
  padding: 1rem;
  text-align: center;
  color: #000;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 126%; /* 47.88px */
}

.left-div {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-right: -70px;
  z-index: 1;
}

.right-div {
  display: flex;
  place-items: center;
  margin-top: 35px;
  margin-left: -70px;
  z-index: 1;
}

.second-left-div {
  display: flex;
  place-items: center;
  margin-top: 35px;
  margin-right: -70px;
  z-index: 1;
}

.second-right-div {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-left: -70px;
  z-index: 1;
}

.steps-content {
  border-radius: 20px;
  background: #fff;
  padding: 2rem;
}

.steps-img {
  object-fit: contain;
}

.steps-title {
  text-align: left;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 126%; /* 40.32px */
  margin-bottom: 10px;
}

.steps-desc {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 25.2px */
}

@media (max-width: 991px) {
  .steps-title {
    font-size: 24px;
  }
  
  .steps-desc {
    font-size: 18px;
  }  
}

@media (max-width: 1200px) {
  .steps-banner {
    font-size: 24px !important;
    padding: 40px;
  }
}


@media (max-width: 670px) {

  .steps {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
  }

  .second-steps {
    padding: 1rem 4rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .left-div {
    display: flex;
    justify-content: center;
    margin-top: 0px !important;
    margin-right: 0px !important;
    padding: 20px;
    z-index: 1;
  }
  
  .right-div {
    display: flex;
    place-items: center;
    margin-top: 0px;
    margin-left: 0px;
    padding: 20px;
    z-index: 1;
  }
  
  .second-left-div {
    display: flex;
    place-items: center;
    margin-top: 0px;
    margin-right: 0px;
    padding: 20px 0px;
    z-index: 1;
  }
  
  .second-right-div {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-left: 0px;
    padding: 20px;
    z-index: 1;
  }

  .middle-div {
    display: none;
  }
}

@media (max-width: 490px) {
  .right-div {
    padding: 0;
  }

  .steps-content {
    padding: 1rem;
  }

  .steps,
  .second-steps {
    padding: 1rem;
  }
}